import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/emp',
    name: 'emp',
    component: () => import('../views/EmpView.vue')
  },
  {
    path: '/allohub',
    name: 'allohub',
    component: () => import('../views/faceView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    props: true,
    component: () => import('../components/detailView.vue')
  },
  {
    path: '/download',
    name: 'download',
    component: () => import('../views/downloadView.vue')
  },
 
  {
    path: '/drug',
    name: 'drug',
    component: () => import('../components/drugFace.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/testView.vue')
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('../views/ElementHelp.vue')
  },
  {
    path: '/WebIntroduce',
    name: 'WebIntroduce',
    component: () => import('../views/ElementWebIntroduce.vue')
  },
  {
    path: '/UseIntroduce',
    name: 'UseIntroduce',
    component: () => import('../views/ElementUseIntroduce.vue')
  },
  {
    path: '/predict',
    name: 'predict',
    component: () => import('../views/predictView.vue')
  },
  {
    path: '/drugView',
    name: 'drugView',
    component: () => import('../components/drugView.vue')
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('../views/errorView.vue')
  },
  {
    path: '/proteinView',
    name: 'proteinView',
    component: () => import('../views/ProteinView.vue')
  },
  {
    path: '/geneView',
    name: 'geneView',
    component: () => import('../views/geneView.vue')
  },
  {
    path: '/drugView2',
    name: 'drugView2',
    component: () => import('../views/drugView.vue')
  },
  {
    path: '/predict2/:id',
    name: 'predict2',
    component: () => import('../views/predictView2.vue')
  },
  {
    path: '/predict3/:id',
    name: 'predict3',
    component: () => import('../views/predictDetailView.vue')
  },
  {
    path: '/Pmmndd',
    name: 'Pmmndd',
    component: () => import('../views/PmmNDDView.vue')
  },
  {
    path: '/Pmmndd/step2/:id',
    name: 'Pmmndd2',
    component: () => import('../views/PmmNDDView2.vue')
  }, {
    path: '/Pmmndd/step3/:id',
    name: 'Pmmndd3',
    component: () => import('../views/PmmNDDView3.vue')
  },{
    path: '/index',
    name: 'csbioTop',
    component: () => import('../views/CsbioTopView.vue')
  },
  {
    path: '/map',
    name: 'map',
    component: () => import('../components/map.vue')
  },
  {
    path: '/sitePredict',
    name: 'sitePredict',
    component: () => import('../views/SitePredictView.vue')
  },
  {
    path: '/predictList',
    name: 'predictList',
    component: () => import('../views/PredictListView.vue')
  },{
    path: '/sitePredict2/:id',
    name: 'sitePredict2',
    component: () => import('../views/SitePredictView2.vue')
  },
  {
    path: '/sitePredict3/:id',
    name: 'sitePredict3',
    component: () => import('../views/SitePredictView3.vue')
  },
  {
    path: '/pdb',
    name: 'pdb',
    component: () => import('../views/PdbView.vue')
  },
  {
    path: '/hs',
    name: 'hs',
    component: () => import('../views/HotSpot.vue')
  },
  {
    path: '/pmm',
    name: 'pmm',
    component: () => import('../views/PmmView.vue')
  }
  
]

const router = new VueRouter({
  mode:'history',
  base:'/',
  routes
})

export default router
